<template>
  <checkouts
    :checkout-name="checkout.lp_id"
    :isLoading="isLoading"
    :checkout="checkout"
  />
</template>

<script>
import Checkouts from '@/components/Checkouts.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { checkoutTypes } from '../data/constants'
export default {
  components: { Checkouts },
  name: 'CheckoutPromo',
  computed: {
    ...mapState('checkout', ['isLoading', 'checkout']),
    ...mapState(['user', 'token']),
  },
  created() {
    this.SET_CHECKOUT_TYPE(checkoutTypes.PROMO)
    this.fetchCheckout(this.$route.params.checkoutId)
      .then((res) => {
        const statistic_id = localStorage.getItem('statistic_id')
        const token = localStorage.getItem('t')
        if (statistic_id && !token) {
          this.$router.push(
            `/lead-checkout/${this.$route.params.checkoutId}?checkout=${checkoutTypes.PROMO}&statistic_id=${statistic_id}`
          )
        } else if (
          res.is_direct_to_sales ||
          (this.$route.query.from === 'auth' && res.lp_id !== 'vsl-type-2')
        ) {
          this.$router.push(
            `/subscription/${this.$route.params.checkoutId}?checkout=${checkoutTypes.PROMO}`
          )
        }
      })
      .catch((e) => console.log(e))
    if (!Object.keys(this.user).length && this.token) this.fetchUser()
  },
  beforeUnmount() {
    this.SET_CHECKOUT_TYPE(null)
  },
  methods: {
    ...mapActions('checkout', ['fetchCheckout']),
    ...mapMutations('checkout', ['SET_CHECKOUT_TYPE']),
    ...mapActions(['fetchUser']),
    ...mapMutations('subscription', ['SET_OPEN']),
  },
}
</script>

<style></style>
